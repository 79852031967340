import { BreakpointObserver } from '@angular/cdk/layout';
import { AsyncPipe, NgClass } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
  RdsButtonModule,
  RdsDatepickerModule,
  RdsFormFieldModule,
  RdsProgressSpinnerModule,
  RdsTooltipModule,
} from '@rds/angular-components';
import {
  CalendarCommonModule,
  CalendarDayModule,
  CalendarWeekModule,
} from 'angular-calendar';
import { endOfWeek } from 'date-fns/endOfWeek';
import { format } from 'date-fns/format';
import { startOfWeek } from 'date-fns/startOfWeek';

import {
  BookingRequestDetailsViewModel,
  CalendarEventTypeEnum,
} from '@bookly/shared';

import { RoomBookingCalendarService } from './room-booking-calendar.service';

@Component({
  selector: 'bsh-room-bookings-calendar',
  standalone: true,
  imports: [
    RdsButtonModule,
    CalendarCommonModule,
    RdsFormFieldModule,
    RdsDatepickerModule,
    FormsModule,
    CalendarWeekModule,
    NgClass,
    RdsTooltipModule,
    CalendarDayModule,
    RdsProgressSpinnerModule,
    AsyncPipe,
  ],
  providers: [RoomBookingCalendarService],
  templateUrl: './room-bookings-calendar.component.html',
  styleUrl: './room-bookings-calendar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoomBookingsCalendarComponent {
  readonly #roomsCalendarService = inject(RoomBookingCalendarService);
  readonly #breakpoint = inject(BreakpointObserver);
  protected isMobileScreen$ = this.#breakpoint.observe('(max-width: 768px)');
  protected readonly roomEvents = this.#roomsCalendarService.roomAvailability;
  protected readonly isLoading =
    this.#roomsCalendarService.isCalendarDataLoading;
  protected bookingRequest: BookingRequestDetailsViewModel | null = null;
  protected viewDate: Date = new Date();
  protected readonly CalendarEventTypeEnum = CalendarEventTypeEnum;
  #roomCalendarId: string | null = null;

  @Input() set roomBooking(value: BookingRequestDetailsViewModel | null) {
    if (value) {
      this.viewDate = new Date(value.bookingDetails.startTime);
      this.bookingRequest = value;
      this.#roomCalendarId = value.roomDetails.calendarId;
      this.viewDateChange(this.viewDate);
    }
  }

  @Input() set roomCalendarId(value: string | null | undefined) {
    if (value) {
      this.#roomCalendarId = value;
      this.viewDateChange(this.viewDate);
    }
  }

  @Input() stickyHeader: boolean = false;

  protected viewDateChange(calendarView: Date) {
    const startDate = startOfWeek(calendarView, {
      weekStartsOn: 1,
    });
    const endDate = endOfWeek(calendarView, { weekStartsOn: 1 });
    if (this.#roomCalendarId) {
      this.#roomsCalendarService
        .getRoomAvailability(
          this.#roomCalendarId,
          format(startDate, "yyyy-MM-dd'T'00:00:00'Z'"),
          format(endDate, "yyyy-MM-dd'T'23:59:59'Z'")
        )
        .subscribe();
    }
  }
}
