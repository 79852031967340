import {
  DestinationFrontendApiResponseEnum,
  NotificationSourceTypeApiResponseEnum,
  NotificationStatusApiResponseEnum,
  NotificationsApiResponseModel,
} from './notifications-api.model';

export type NotificationsModel = NotificationsApiResponseModel;

export type NotificationStatusEnum = NotificationStatusApiResponseEnum;
export const NotificationStatusEnum = { ...NotificationStatusApiResponseEnum };

export type NotificationSourceTypeEnum = NotificationSourceTypeApiResponseEnum;
export const NotificationSourceTypeEnum = {
  ...NotificationSourceTypeApiResponseEnum,
};

export type DestinationFrontendEnum = DestinationFrontendApiResponseEnum;
export const DestinationFrontendEnum = {
  ...DestinationFrontendApiResponseEnum,
};
