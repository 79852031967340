export interface AuthApiResponse {
  idtoken: string;
  refreshtoken: string;
  roles: UserGroups[];
}

export interface User {
  email: string;
  name: string;
  picture: string;
  roles: UserGroups[];
}

export enum UserGroups {
  HOST_GROUP = 'HOST_GROUP',
  SERVICE_PROVIDERS_GROUP = 'SERVICE_PROVIDERS_GROUP',
}
