import { Injectable, inject } from '@angular/core';
import {
  MessagePayload,
  Messaging,
  getToken,
  onMessage,
} from '@angular/fire/messaging';
import { Subject, lastValueFrom } from 'rxjs';

import { NotificationsService, environment } from '@bookly/shared';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationsService {
  readonly #notificationsService = inject(NotificationsService);
  readonly #messaging = inject(Messaging);
  readonly #messageSubject = new Subject<unknown>();
  public message$ = this.#messageSubject.asObservable();

  public async init() {
    const serviceWorkerRegistration = await navigator.serviceWorker.ready;
    const permission = await Notification.requestPermission();
    const storedDeviceToken = localStorage.getItem('deviceToken');
    let fcmToken: string | null = null;

    if (permission === 'granted') {
      fcmToken = await getToken(this.#messaging, {
        serviceWorkerRegistration,
        vapidKey: environment.vapidKey,
      });

      if (fcmToken && fcmToken !== storedDeviceToken) {
        try {
          await lastValueFrom(
            this.#notificationsService.registerDeviceToken(fcmToken)
          );
          localStorage.setItem('deviceToken', fcmToken);
        } catch (error) {
          console.error(error);
        }
      }
    }

    onMessage(this.#messaging, (message: MessagePayload) => {
      this.#messageSubject.next(message.notification);
    });
  }
}
