import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { RouterLink } from '@angular/router';
import {
  RdsBadgeComponent,
  RdsButtonModule,
  RdsDividerModule,
  RdsIconComponent,
  RdsLinkComponent,
} from '@rds/angular-components';

import { BookingSummaryComponent } from '@bookly/shared';

import { BookingRequestService } from '../../services/booking-request.service';

@Component({
  selector: 'bus-booking-request-confirmation',
  standalone: true,
  imports: [
    CommonModule,
    BookingSummaryComponent,
    RdsIconComponent,
    RdsLinkComponent,
    RouterLink,
    RdsBadgeComponent,
    RdsDividerModule,
    RdsButtonModule,
  ],
  templateUrl: './booking-request-confirmation.component.html',
  styleUrl: './booking-request-confirmation.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingRequestConfirmationComponent {
  readonly #bookingRequestService = inject(BookingRequestService);
  readonly bookingRequestData = this.#bookingRequestService.bookingRequestData;
}
