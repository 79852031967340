<div rdsDropdown placement="bottom-right" [autoClose]="true">
  <button
    class="dropdown-toggle"
    size="l"
    rds-dropdown-toggle-icon-button
    trigger="click">
    <rds-icon
      class="action"
      namespace="outlined"
      icon="notification"
      rdsBadge
      [rdsBadgeLabel]="unreadCount()"
      [rdsBadgeColor]="+unreadCount() > 0 ? 'error' : 'neutral'"></rds-icon>
  </button>
  <div class="notifications" *rdsDropdownMenu>
    <div class="header">
      <p class="title">Notifications</p>
      <button rds-text-button (click)="markAllAsRead()">
        Mark all as read
      </button>
      <button rds-icon-button size="m" class="close-button">
        <rds-icon icon="close" namespace="outlined"></rds-icon>
      </button>
    </div>
    @if (isLoading()) {
      <div class="loader">
        <rds-progress-spinner></rds-progress-spinner>
      </div>
    }
    @for (
      notification of latestNotifications();
      track notification.notificationId
    ) {
      <bsh-notification [notification]="notification"></bsh-notification>
    }
    <button
      class="bottom-button"
      rds-text-button
      (click)="showAllNotifications()">
      All notifications
    </button>
  </div>
</div>
