import { Injectable, inject, signal } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { EMPTY, finalize, first, lastValueFrom } from 'rxjs';

import { AuthApiService } from './auth-api.service';
import { User, UserGroups } from '../model/auth.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  readonly #router = inject(Router);
  readonly #authApiService = inject(AuthApiService);
  readonly #activatedRoute = inject(ActivatedRoute);

  readonly #isLoginPendingSignal = signal(false);
  #user: User | null = null;
  #token: string | null = null;
  #refreshToken: string | null = null;

  get isLoginPending() {
    return this.#isLoginPendingSignal.asReadonly();
  }

  get token() {
    return this.#token;
  }

  get user() {
    return this.#user;
  }

  get isHost() {
    return this.#user?.roles.includes(UserGroups.HOST_GROUP);
  }

  get refreshToken() {
    return this.#refreshToken;
  }

  public init() {
    const id_token = localStorage.getItem('id_token');
    const refreshToken = localStorage.getItem('refresh_token');
    const roles = localStorage.getItem('roles');

    if (id_token) {
      this.#token = id_token;
      this.#refreshToken = refreshToken;
      this.#user = this.#parseJwt(this.#token);
      this.#user = {
        ...this.#parseJwt(this.#token),
        roles: roles ? JSON.parse(roles) : [],
      };
      return EMPTY;
    }

    return this.#activatedRoute.queryParams
      .pipe(first(params => 'code' in params))
      .subscribe(() => {
        const queryParams = this.#activatedRoute.snapshot.queryParams;
        this.#router.navigate(['/']);
        this.requestToken(queryParams);
      });
  }

  public login() {
    this.#authApiService.redirectToGoogle();
  }

  public refresh(token: string) {
    localStorage.setItem('id_token', token);
    this.#token = token;
  }

  public async logout() {
    const deviceToken = localStorage.getItem('deviceToken');
    if (deviceToken) {
      await lastValueFrom(this.#authApiService.logout(deviceToken));
    }
    const registrations = await navigator.serviceWorker.getRegistrations();
    for (const registration of registrations) {
      await registration.unregister();
    }
    localStorage.clear();
    void this.#router.navigate(['/login']);
  }

  public requestToken(authCode: Params) {
    this.#isLoginPendingSignal.set(true);
    this.#authApiService
      .requestToken(authCode)
      .pipe(
        finalize(() => {
          this.#isLoginPendingSignal.set(false);
        })
      )
      .subscribe(resp => {
        if (resp.idtoken !== 'Please provide a code') {
          localStorage.setItem('id_token', resp.idtoken);
          localStorage.setItem('refresh_token', resp.refreshtoken);
          localStorage.setItem('roles', JSON.stringify(resp.roles));
          this.#token = resp.idtoken;
          this.#refreshToken = resp.refreshtoken;
          this.#user = { ...this.#parseJwt(this.#token), roles: resp.roles };
          this.#router.navigate(['/']);
        }
      });
  }

  #parseJwt(token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(code => '%' + ('00' + code.charCodeAt(0).toString(16)).slice(-2))
        .join('')
    );

    return JSON.parse(jsonPayload);
  }
}
