@let bookingDetails = bookingRequestData();

<a rds-link standalone size="m" routerLink="" class="back-nav">
  <rds-icon namespace="filled" icon="arrow_left"></rds-icon>
  Back to search results
</a>

@if (bookingDetails) {
  <div class="header">
    <p class="ui-heading-1-bold">{{ bookingDetails.roomInfo.roomName }}</p>
  </div>

  <div class="confirmation-container">
    <div class="confirm-title">
      <rds-icon
        class="confirm-icon"
        namespace="outlined"
        icon="checkmark_circle"
        size="l"></rds-icon>
      <p class="ui-heading-3">Thanks for creating this booking request.</p>
      <p class="ui-label-m-bold">
        Your Bookly ID:
        {{ bookingDetails.id }}
      </p>
    </div>
    <rds-divider></rds-divider>
    <bsh-booking-summary
      [bookingDetails]="bookingDetails"></bsh-booking-summary>
    <p class="ui-label-m info-text">
      You can check and modify bookings in “My Bookings” section.
    </p>
    <rds-divider></rds-divider>
    <div class="buttons-container">
      <button rds-secondary-button size="l" routerLink="/">Close</button>
      <button rds-secondary-button size="l" routerLink="/dashboard/bookings">
        Go to My Bookings
      </button>
    </div>
  </div>
}
