import { Injectable, WritableSignal, inject, signal } from '@angular/core';
import { filter, finalize, switchMap, tap } from 'rxjs';

import { NotificationsApiService } from './notifications-api.service';
import { AuthService } from '../../auth';
import {
  DestinationFrontendEnum,
  NotificationStatusEnum,
  NotificationsModel,
} from '../model/notifications.model';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  readonly #authService = inject(AuthService);
  readonly #notificationsApiService = inject(NotificationsApiService);

  readonly #unreadCount = signal('0');
  readonly #allNotifications: WritableSignal<NotificationsModel[]> = signal([]);
  readonly #isLoading = signal(false);
  readonly #latestFiveNotifications: WritableSignal<NotificationsModel[]> =
    signal([]);
  #counter = 0;
  #destination!: DestinationFrontendEnum;

  public get isLoading() {
    return this.#isLoading.asReadonly();
  }

  public get unreadCount() {
    return this.#unreadCount.asReadonly();
  }

  public get allNotifications() {
    return this.#allNotifications.asReadonly();
  }

  public get latestNotifications() {
    return this.#latestFiveNotifications.asReadonly();
  }

  public getNotifications(destination: DestinationFrontendEnum) {
    this.#isLoading.set(true);
    this.#counter = 0;
    this.#destination = destination;
    return (
      destination === DestinationFrontendEnum.User
        ? this.#notificationsApiService.getUserNotifications()
        : this.#notificationsApiService.getAdminNotifications(
            this.#authService.user?.email as string
          )
    ).pipe(
      tap(res => {
        res.forEach(notification => {
          if (notification.status === NotificationStatusEnum.Unread) {
            this.#counter++;
          }
        });
        this.#latestFiveNotifications.set(res.slice(0, 5));
        this.#allNotifications.set(res);
        this.#unreadCount.set(this.#counter.toString());
        if (navigator.setAppBadge) {
          void navigator.setAppBadge(this.#counter);
        }
      }),
      finalize(() => this.#isLoading.set(false))
    );
  }

  public updateNotifications() {
    return (
      this.#destination === DestinationFrontendEnum.User
        ? this.#notificationsApiService.getUserNotifications()
        : this.#notificationsApiService.getAdminNotifications(
            this.#authService.user?.email as string
          )
    ).pipe(
      filter(
        notifications =>
          notifications.length > 0 &&
          notifications[0].notificationId !==
            this.allNotifications()[0].notificationId
      ),
      tap(notifications => {
        this.#counter = 0;
        notifications.forEach(notification => {
          if (notification.status === NotificationStatusEnum.Unread) {
            this.#counter++;
          }
        });
        this.#latestFiveNotifications.set(notifications.slice(0, 5));
        this.#allNotifications.set(notifications);
        this.#unreadCount.set(this.#counter.toString());
        if (navigator.setAppBadge) {
          void navigator.setAppBadge(this.#counter);
        }
      })
    );
  }

  public markNotificationAsRead(notificationId: number) {
    if (this.#destination === DestinationFrontendEnum.User) {
      return this.#notificationsApiService.markUserNotificationAsRead(
        notificationId
      );
    }
    return this.#notificationsApiService.markAdminNotificationAsRead(
      this.#authService.user?.email as string,
      notificationId
    );
  }

  public markAllNotificationAsRead() {
    this.#isLoading.set(true);
    return (
      this.#destination === DestinationFrontendEnum.User
        ? this.#notificationsApiService.markAllUserNotificationAsRead()
        : this.#notificationsApiService.markAllAdminNotificationAsRead(
            this.#authService.user?.email as string
          )
    ).pipe(
      switchMap(() => this.getNotifications(this.#destination)),
      finalize(() => this.#isLoading.set(false))
    );
  }

  public registerDeviceToken(deviceToken: string) {
    return this.#notificationsApiService.registerDeviceToken(deviceToken);
  }

  public decreaseCounter() {
    this.#counter--;
    this.#unreadCount.set(this.#counter.toString());
    if (navigator.setAppBadge) {
      void navigator.setAppBadge(this.#counter);
    }
  }
}
