import { Injectable, inject, signal } from '@angular/core';
import { finalize, tap } from 'rxjs';

import {
  BookingRequestApiService,
  ChatMessageApiRequestModel,
  ChatMessageModel,
  GoogleAnalyticsEventCategory,
  GoogleAnalyticsEventName,
  GoogleAnalyticsService,
} from '@bookly/shared';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  readonly #bookingRequestApiService = inject(BookingRequestApiService);
  readonly #googleAnalyticService = inject(GoogleAnalyticsService);

  readonly #chatMessagesSignal = signal<ChatMessageModel[]>([]);
  readonly #isMessageLoadingSignal = signal(false);

  get chatMessages() {
    return this.#chatMessagesSignal.asReadonly();
  }

  get isMessageLoading() {
    return this.#isMessageLoadingSignal.asReadonly();
  }
  public getMessages(bookingId: number) {
    this.#chatMessagesSignal.set([]);
    this.#isMessageLoadingSignal.set(true);
    return this.#bookingRequestApiService.getMessages(bookingId).pipe(
      tap(chatMessages => this.#chatMessagesSignal.set(chatMessages)),
      finalize(() => this.#isMessageLoadingSignal.set(false))
    );
  }

  public markAllMessagesAsRead(bookingId: number) {
    return this.#bookingRequestApiService
      .markAllMessagesAsRead(bookingId)
      .pipe(tap(chatMessages => this.#chatMessagesSignal.set(chatMessages)));
  }

  public postMessage(message: ChatMessageApiRequestModel) {
    this.#isMessageLoadingSignal.set(true);
    return this.#bookingRequestApiService.postMessage(message).pipe(
      tap(chatMessages => {
        this.#chatMessagesSignal.set(chatMessages);
        this.#googleAnalyticService.trackEvent(
          GoogleAnalyticsEventName.MessageSent,
          GoogleAnalyticsEventCategory.BookingRequest,
          message.bookingId
        );
      }),
      finalize(() => this.#isMessageLoadingSignal.set(false))
    );
  }
}
