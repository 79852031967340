<a
  rds-link
  standalone
  size="m"
  [routerLink]="'/dashboard/bookings'"
  class="nav">
  <rds-icon namespace="filled" icon="arrow_left"></rds-icon>
  Back to My Bookings
</a>

@let bookingData = bookingDetails();
@let roomData = roomDetails();

@if (bookingData && roomData) {
  <div class="header-wrapper">
    <p class="ui-heading-1-bold">
      {{ bookingData.roomInfo.roomName }} {{ bookingData.roomInfo.siteName }}
      {{ bookingData.roomInfo.buildingName }}
      {{ bookingData.roomInfo.floorCode }}
    </p>
    <div class="header-buttons">
      <button
        class="visit-button"
        rds-secondary-button
        size="l"
        [routerLink]="'/dashboard/rooms/' + bookingData.roomInfo.roomDexId">
        Visit Room Page
      </button>
      <!--      TODO uncomment when favourites feature will be implemented-->
      <!--      <button rds-secondary-button size="l">-->
      <!--        <rds-icon namespace="outlined" icon="heart"></rds-icon>-->
      <!--      </button>-->
    </div>
  </div>
  <div class="details-container">
    <div class="details-card">
      <div class="details-header">
        <p>
          Your booking status:
          <rds-badge
            type="minimal"
            size="m"
            class="badge"
            [label]="bookingData.status.text"
            [color]="bookingData.status.color"></rds-badge>
        </p>
        @if (!(isPastBooking || isEdit()) && isAwaitingApproval) {
          <div class="buttons-container">
            <button rds-primary-button size="m" (click)="edit()">
              <rds-icon namespace="outlined" icon="edit"></rds-icon>
              Edit Booking
            </button>
            <button rds-secondary-button size="m" (click)="deleteBooking()">
              <rds-icon namespace="outlined" icon="delete"></rds-icon>
              Delete Booking
            </button>
          </div>
        }
      </div>
      <rds-tabs #tabs>
        <rds-tab label="Event details">
          <div class="card-container">
            @if (!isEdit()) {
              <bsh-booking-summary
                [bookingDetails]="bookingData"></bsh-booking-summary>
            } @else {
              <bus-room-booking-form
                [roomDetails]="roomData"
                [roomLayouts]="roomLayouts()"
                [bookingRequest]="bookingData"
                (bookingRequestFormData)="updateRoomBooking($event)"
                (cancelHandler)="cancel()">
              </bus-room-booking-form>
            }
          </div>
        </rds-tab>
        <rds-tab
          #messagesTab
          label="Messages"
          [active]="
            bookingBaseData.setActiveTab === NotificationSourceTypeEnum.Chat
          ">
          <div class="card-container">
            @if (isMessageLoading()) {
              <div class="loader">
                <rds-progress-spinner></rds-progress-spinner>
              </div>
            }
            <bsh-messages
              [bookingRequestStatus]="bookingData.status.value"
              [chatMessages]="chatMessages()"
              (sendMessage)="postMessage($event)"></bsh-messages>
          </div>
        </rds-tab>
        <rds-tab label="Calendar">
          <div class="calendar-container">
            <bsh-room-bookings-calendar
              [roomBooking]="{
                bookingDetails: bookingData,
                roomDetails: roomData,
              }"></bsh-room-bookings-calendar>
          </div>
        </rds-tab>
      </rds-tabs>
    </div>
  </div>
  <div class="details-container">
    <div class="room-container">
      <bus-room-details-card [roomDetails]="roomData"></bus-room-details-card>
    </div>
  </div>
}

@if (isLoading()) {
  <div class="loader">
    <rds-progress-spinner></rds-progress-spinner>
  </div>
}
