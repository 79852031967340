import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnInit,
  ViewChild,
  computed,
  inject,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import {
  RdsBadgeComponent,
  RdsButtonModule,
  RdsDialogService,
  RdsDividerModule,
  RdsIconComponent,
  RdsLinkComponent,
  RdsProgressSpinnerModule,
  RdsTabComponent,
  RdsTabModule,
  RdsTabsComponent,
} from '@rds/angular-components';
import { isAfter } from 'date-fns';
import { combineLatest, filter, switchMap, tap } from 'rxjs';

import {
  BookingBasicData,
  BookingRequestBaseModel,
  BookingRequestUpdateModel,
  BookingStatusEnum,
  BookingSummaryComponent,
  ConfirmationComponent,
  MessageService,
  MessagesComponent,
  NotificationSourceTypeEnum,
  RoomBookingsCalendarComponent,
} from '@bookly/shared';

import { RoomDetailsCardComponent } from '../../../rooms/components/room-details-card/room-details-card.component';
import { RoomsService } from '../../../rooms/services/rooms.service';
import { RoomBookingFormComponent } from '../../components/room-booking-form/room-booking-form.component';
import { BookingRequestService } from '../../services/booking-request.service';
import { BookingService } from '../../services/booking.service';

@Component({
  selector: 'bus-booking-details-view',
  standalone: true,
  imports: [
    BookingSummaryComponent,
    RdsProgressSpinnerModule,
    RdsLinkComponent,
    RouterLink,
    RdsIconComponent,
    RdsButtonModule,
    RdsBadgeComponent,
    RdsDividerModule,
    RdsTabModule,
    RoomBookingsCalendarComponent,
    RoomDetailsCardComponent,
    RoomBookingFormComponent,
    MessagesComponent,
  ],
  templateUrl: './booking-details-view.component.html',
  styleUrl: './booking-details-view.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingDetailsViewComponent implements OnInit {
  readonly #activatedRoute = inject(ActivatedRoute);
  readonly #router = inject(Router);
  readonly #bookingService = inject(BookingService);
  readonly #bookingRequestService = inject(BookingRequestService);
  readonly #roomsService = inject(RoomsService);
  readonly #messageService = inject(MessageService);
  readonly #destroyRef = inject(DestroyRef);
  readonly #dialogService = inject(RdsDialogService);

  protected readonly bookingDetails = this.#bookingService.bookingDetails;
  protected readonly roomDetails = this.#roomsService.roomDetails;
  protected readonly roomLayouts = this.#roomsService.roomLayouts;
  protected readonly chatMessages = this.#messageService.chatMessages;
  protected readonly isMessageLoading = this.#messageService.isMessageLoading;
  protected readonly isLoading = computed(
    () =>
      this.#bookingService.isBookingDetailsLoading() ||
      this.#roomsService.isRoomDetailsLoading() ||
      this.#bookingRequestService.isLoading()
  );
  protected readonly NotificationSourceTypeEnum = NotificationSourceTypeEnum;
  protected isPastBooking: boolean = false;
  protected isAwaitingApproval: boolean = false;
  protected isEdit = signal(false);
  protected bookingBaseData!: BookingBasicData;
  protected messagesTab!: RdsTabComponent;

  @ViewChild('messagesTab', { static: false })
  set message(component: RdsTabComponent) {
    this.messagesTab = component;
    if (this.messagesTab) {
      this.messagesTab.stateChanges
        .pipe(
          filter(() => this.messagesTab.active),
          filter(() =>
            this.chatMessages().some(message => !message.readByViewer)
          ),
          switchMap(() =>
            this.#messageService.markAllMessagesAsRead(
              this.bookingBaseData.bookingId
            )
          ),
          takeUntilDestroyed(this.#destroyRef)
        )
        .subscribe();
    }
  }

  @ViewChild('tabs', { static: false })
  protected tabs!: RdsTabsComponent;

  ngOnInit(): void {
    this.#activatedRoute.params
      .pipe(
        switchMap(({ bookingId, roomId }) => {
          this.bookingBaseData = { bookingId: bookingId, roomDexId: roomId };
          return combineLatest([
            this.#bookingService.getBookingDetails(bookingId).pipe(
              tap(booking => {
                this.isPastBooking = !isAfter(booking.startTime, new Date());
                this.isAwaitingApproval =
                  booking.status.value === BookingStatusEnum.InProgress;
              })
            ),
            this.#messageService.getMessages(bookingId),
            this.#roomsService.getRoomData(roomId),
          ]);
        }),
        takeUntilDestroyed(this.#destroyRef)
      )
      .subscribe();
  }

  protected deleteBooking() {
    const booking = this.bookingDetails();
    if (booking) {
      const dialog = this.#dialogService.open(ConfirmationComponent, {
        data: {
          header: 'Are you sure you want to delete this booking?',
          message: 'Operation is irreversible',
        },
      });
      dialog
        .afterClosed()
        .pipe(
          takeUntilDestroyed(this.#destroyRef),
          filter(Boolean),
          switchMap(() => {
            return this.#bookingService.deleteBooking(booking.id);
          })
        )
        .subscribe(() => {
          void this.#router.navigate(['dashboard', 'bookings']);
        });
    }
  }

  protected updateRoomBooking(requestData: BookingRequestBaseModel) {
    const request = {
      ...requestData,
      id: this.bookingDetails()?.id,
    } as BookingRequestUpdateModel;
    this.#bookingRequestService
      .updateRoomBooking(request)
      .subscribe(response => {
        this.#bookingService.refreshBookingDetails(response);
        this.cancel();
      });
  }

  protected edit() {
    this.isEdit.set(true);
    this.tabs.selectTab(0);
  }

  protected cancel() {
    this.isEdit.set(false);
  }

  protected postMessage(message: string) {
    const booking = this.bookingDetails();
    if (booking) {
      this.#messageService
        .postMessage({
          bookingId: booking.id,
          message,
        })
        .subscribe();
    }
  }
}
