@if (isLoading()) {
  <div class="loader">
    <rds-progress-spinner></rds-progress-spinner>
  </div>
} @else {
  <div class="my-bookings-container">
    <rds-header-navigation-primary class="my-bookings-tabs">
      <rds-header-link
        (toggle)="tab = BookingTabs.UPCOMING"
        [active]="tab === BookingTabs.UPCOMING">
        Upcoming Bookings
      </rds-header-link>
      <rds-header-link
        (toggle)="tab = BookingTabs.PAST"
        [active]="tab === BookingTabs.PAST">
        Past Bookings
      </rds-header-link>
    </rds-header-navigation-primary>

    @if (tab === BookingTabs.UPCOMING) {
      <bus-booking-list [bookings]="upcomingBookings()"></bus-booking-list>
    } @else if (tab === BookingTabs.PAST) {
      <bus-booking-list [bookings]="pastBookings()"></bus-booking-list>
    }
  </div>
}
