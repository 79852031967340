import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  inject,
} from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import {
  filter,
  finalize,
  fromEvent,
  interval,
  switchMap,
  takeWhile,
  tap,
} from 'rxjs';

import {
  AuthService,
  NotificationsService,
  PushNotificationsService,
  environment,
} from '@bookly/shared';

@Component({
  standalone: true,
  imports: [RouterOutlet],
  selector: 'app-root',
  templateUrl: './app.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  readonly #notificationService = inject(NotificationsService);
  readonly #pushNotificationService = inject(PushNotificationsService);
  readonly #authService = inject(AuthService);
  readonly #router = inject(Router);

  ngOnInit() {
    fromEvent<MessageEvent>(window, 'message')
      .pipe(
        filter((event: MessageEvent) => event.origin === environment.hiSiteUrl),
        tap((e: MessageEvent) => {
          const parsedData = JSON.parse(e.data);
          if (parsedData.access_token) {
            localStorage.setItem('id_token', parsedData.access_token);
            localStorage.setItem('refresh_token', parsedData.refresh_token);
            this.#authService.init();
          }
        }),
        takeWhile(() => this.#authService.token === null),
        finalize(() => this.#router.navigate(['/']))
      )
      .subscribe();

    if ('Notification' in window && Notification.permission === 'granted') {
      this.#pushNotificationService.message$
        .pipe(switchMap(() => this.#notificationService.updateNotifications()))
        .subscribe();

      return;
    }

    interval(30000)
      .pipe(switchMap(() => this.#notificationService.updateNotifications()))
      .subscribe();
  }
}
