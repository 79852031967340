<div class="card-container">
  <div class="room-card-image">
    @if (roomDetails.photos) {
      <img alt="room photo" [ngSrc]="roomDetails.photos[0].url" fill />
    }
  </div>
  <div class="room-details">
    <div class="details-section">
      <p class="ui-label-s-bold">Location</p>
      <p class="ui-body-l">
        {{ roomDetails.siteName }} {{ roomDetails.buildingName }}
        {{ roomDetails.floorCode }}
      </p>
    </div>
    <div class="details-section">
      <p class="ui-label-s-bold">Space up to</p>
      <p class="ui-body-l">{{ roomDetails.capacity }} people</p>
    </div>
    <div class="details-section">
      <p class="ui-label-s-bold">Description</p>
      <p class="ui-body-l">
        {{ roomDetails.description }}
      </p>
    </div>
    <div class="details-section">
      <p class="ui-label-s-bold">Room includes</p>

      <p class="ui-body-l">
        @for (feature of roomDetails.features; track feature.id) {
          {{ feature.name }}&comma;
        }
      </p>
    </div>
    @if (layouts.length > 0) {
      <rds-form-field labelPosition="top">
        <rds-control-label>
          Select Layout
          <rds-icon icon="info" namespace="outlined"> </rds-icon>
        </rds-control-label>
        <rds-select [formControl]="layoutControl">
          @for (layout of layouts; track layout.layoutDexUUID) {
            <rds-select-option [value]="layout">
              {{ layout.layoutName }}
            </rds-select-option>
          }
        </rds-select>
      </rds-form-field>
      <div class="layout-details">
        @if (layoutControl.value) {
          <div class="layout-info">
            <p class="ui-label-s">
              Layout capacity:
              {{ layoutControl.value.capacity.min }}
              -
              {{ layoutControl.value.capacity.max }}
            </p>
            <p class="ui-label-s">Layout setup time:</p>
            <p class="ui-label-s">
              {{ layoutControl.value.setupTime.before }}
              minutes before -
              {{ layoutControl.value.setupTime.after }}
              minutes after
            </p>
          </div>
        }
        @if (layoutControl.value && layoutControl.value.imageURL) {
          <div class="layout-image">
            <img
              alt="layout-image"
              [ngSrc]="layoutControl.value.imageURL"
              fill />
          </div>
        }
      </div>
    }
  </div>
</div>
