import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@bookly/shared';

import { NotificationsApiResponseModel } from '../model/notifications-api.model';

@Injectable({
  providedIn: 'root',
})
export class NotificationsApiService {
  readonly #http = inject(HttpClient);
  readonly #adminNotificationsUrl = environment.admin.notificationsApi;
  readonly #userNotificationsUrl = environment.user.notificationsApi;
  readonly #deviceTokenUrl = environment.shared.deviceTokenApi;

  public getAdminNotifications(userEmail: string) {
    return this.#http.get<NotificationsApiResponseModel[]>(
      this.#adminNotificationsUrl + userEmail
    );
  }

  public getUserNotifications() {
    return this.#http.get<NotificationsApiResponseModel[]>(
      this.#userNotificationsUrl
    );
  }

  public markAdminNotificationAsRead(
    userEmail: string,
    notificationId: number
  ) {
    return this.#http.put(
      this.#adminNotificationsUrl + userEmail + `/${notificationId}`,
      null
    );
  }

  public markUserNotificationAsRead(notificationId: number) {
    return this.#http.put(
      this.#userNotificationsUrl + `/${notificationId}`,
      null
    );
  }

  public markAllAdminNotificationAsRead(userEmail: string) {
    return this.#http.put(this.#adminNotificationsUrl + userEmail, null);
  }

  public markAllUserNotificationAsRead() {
    return this.#http.put(this.#userNotificationsUrl, null);
  }

  public registerDeviceToken(deviceToken: string): Observable<string> {
    return this.#http.post(this.#deviceTokenUrl + deviceToken, null, {
      responseType: 'text',
    });
  }
}
