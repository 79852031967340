import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, map } from 'rxjs';

import {
  BookingRequestDetailsApiResponseModel,
  BookingRequestDetailsModel,
  bookingDetailsConverter,
  bookingDetailsListConverter,
  environment,
} from '@bookly/shared';

@Injectable({
  providedIn: 'root',
})
export class BookingApiService {
  readonly #http = inject(HttpClient);
  readonly #myBookingUrl = environment.user.myBookingsApi;
  readonly #bookingUrl = environment.user.roomBookingApi;

  public getUpcomingBookings(): Observable<BookingRequestDetailsModel[]> {
    return this.#http
      .get<
        BookingRequestDetailsApiResponseModel[]
      >(`${this.#myBookingUrl}upcoming_bookings`)
      .pipe(map(bookings => bookingDetailsListConverter(bookings)));
  }

  public getPastBookings(): Observable<BookingRequestDetailsModel[]> {
    return this.#http
      .get<
        BookingRequestDetailsApiResponseModel[]
      >(`${this.#myBookingUrl}past_booking`)
      .pipe(map(bookings => bookingDetailsListConverter(bookings)));
  }

  public deleteBooking(bookingId: number): Observable<never> {
    return this.#http.delete<never>(`${this.#bookingUrl}/${bookingId}`);
  }

  public getBookingDetails(
    bookingId: number
  ): Observable<BookingRequestDetailsModel> {
    return this.#http
      .get<BookingRequestDetailsApiResponseModel>(
        this.#bookingUrl + '/' + bookingId
      )
      .pipe(map(bookingDetails => bookingDetailsConverter(bookingDetails)));
  }
}
