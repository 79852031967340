<div class="list-container">
  @if (bookings.length === 0) {
    <div class="no-bookings">
      <rds-empty-state>
        <img
          rds-empty-state-image
          src="assets/no-results.png"
          alt="no results image" />
        <rds-empty-state-title> There are no bookings </rds-empty-state-title>
      </rds-empty-state>
    </div>
  }
  @for (booking of bookings; track booking.id) {
    <div class="booking-item">
      <div class="booking-item-image">
        @if (booking?.roomInfo?.photoUrl) {
          <img
            [ngSrc]="booking.roomInfo.photoUrl"
            width="397"
            height="242"
            alt="Room img"
            class="booking-item-image" />
        }
      </div>
      <div class="booking-item-info-container">
        <div class="booking-item-header">
          <p class="ui-heading-2-bold--l">
            {{ 'ID #' + booking.id + ' - ' + booking.eventName }}
          </p>
          <rds-badge
            type="minimal"
            size="m"
            [label]="booking.status.text"
            [color]="booking.status.color"></rds-badge>
        </div>
        <div class="booking-item-content">
          <div class="booking-item-info">
            <div class="booking-item-info-list">
              <rds-icon
                namespace="outlined"
                icon="flag"
                class="booking-item-icon" />
              <div>
                <p class="ui-component-text-s">Location:</p>
                <p class="ui-body-m-bold">
                  {{
                    booking.roomInfo.siteName +
                      '/' +
                      booking.roomInfo.floorCode +
                      '/' +
                      booking.roomInfo.roomName
                  }}
                </p>
              </div>
            </div>
            <div class="booking-item-info-list">
              <rds-icon
                namespace="outlined"
                icon="calendar"
                class="booking-item-icon" />
              <div>
                <p class="ui-component-text-s">Date & Time:</p>
                <p class="ui-body-m-bold">
                  {{ booking.startTime | eventDateTime: booking.endTime }}
                </p>
              </div>
            </div>
          </div>
          <div class="booking-item-actions">
            <button
              rds-secondary-button
              [routerLink]="
                '/dashboard/bookings/' +
                booking.id +
                '/' +
                booking.roomInfo.roomDexId
              ">
              View details
            </button>
          </div>
        </div>
      </div>
    </div>
  }
</div>
