import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

import { AuthService } from '@bookly/shared';

export const hostGuard: CanActivateFn = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _route: ActivatedRouteSnapshot,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _state: RouterStateSnapshot
) => {
  const authService = inject(AuthService);
  const router = inject(Router);

  return authService.user && authService.isHost ? true : router.navigate(['/']);
};
