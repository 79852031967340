<a rds-link standalone size="m" [routerLink]="''" class="back-nav">
  <rds-icon namespace="filled" icon="arrow_left"></rds-icon>
  Back to search results
</a>

@let roomData = roomDetails();

@if (roomData) {
  <div class="header-wrapper">
    <p class="ui-heading-1-bold">{{ roomData.roomName }}</p>
    <button
      rds-primary-button
      size="l"
      routerLink="../{{ roomData.roomDexId }}/create">
      <rds-icon icon="calendar" namespace="filled" />
      Create event here
    </button>
  </div>

  <rds-tabs>
    <rds-tab label="Details">
      <div class="card-container">
        <bus-room-details-card
          [roomDetails]="roomData"
          [roomLayouts]="roomLayouts()"></bus-room-details-card>
      </div>
    </rds-tab>
    <rds-tab label="Calendar">
      <div class="calendar-container">
        <bsh-room-bookings-calendar
          [roomCalendarId]="roomData.calendarId"></bsh-room-bookings-calendar>
      </div>
    </rds-tab>
  </rds-tabs>
}

@if (isLoading()) {
  <div class="loader">
    <rds-progress-spinner></rds-progress-spinner>
  </div>
}
