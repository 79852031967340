<div class="room-card">
  <div class="room-card-image">
    @if (room.mainPhoto) {
      <img [ngSrc]="room.mainPhoto" fill alt="room photo" />
    }
  </div>
  <div class="room-info">
    <div>
      <p class="ui-heading-3">{{ room.name }}</p>
      <p class="ui-heading-3">
        {{ room.siteName }} {{ room.buildingName }} {{ room.floorName }}
      </p>
    </div>
    <p class="ui-label-s-bold c-600-grey-2">
      Space: up to {{ room.capacity }} people
    </p>
  </div>
</div>
