import { Injectable, inject } from '@angular/core';
import { Analytics, logEvent } from '@angular/fire/analytics';

export enum GoogleAnalyticsEventName {
  Approve = 'ADMIN Request Approved',
  Reject = 'ADMIN Request Rejected',
  Cancel = 'ADMIN Request Canceled',
  EditAdmin = 'ADMIN Request Edited',
  EditUser = 'USER Request Edited',
  MessageSent = 'Request message sent',
  CreateEvent = 'Created new event',
  DeleteEvent = 'Deleted event',
}

export enum GoogleAnalyticsEventCategory {
  BookingRequest = 'Booking Request',
}

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  readonly analytics = inject(Analytics);

  trackEvent(
    eventName: GoogleAnalyticsEventName,
    eventCategory: GoogleAnalyticsEventCategory,
    eventDetails: string | number
  ) {
    logEvent(this.analytics, eventName, {
      event_category: eventCategory,
      event_label: eventName,
      value: `Request ID: ${eventDetails}`,
    });
  }
}
