import { NgOptimizedImage } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  RdsButtonModule,
  RdsDateRangeInputComponent,
  RdsDateRangePickerComponent,
  RdsDatepickerToggleComponent,
  RdsEndDateDirective,
  RdsFormFieldModule,
  RdsIconComponent,
  RdsStartDateDirective,
  RdsSwitchModule,
  RdsTimepickerClearComponent,
  RdsTimepickerComponent,
  RdsTimepickerInputDirective,
  RdsTooltipModule,
} from '@rds/angular-components';

import {
  BookingRequestBaseModel,
  BookingRequestDetailsModel,
  RoomBookingFormService,
  RoomDetailsModel,
  RoomLayoutsModel,
} from '@bookly/shared';

@Component({
  selector: 'bus-room-booking-form',
  standalone: true,
  imports: [
    FormsModule,
    RdsFormFieldModule,
    ReactiveFormsModule,
    RdsStartDateDirective,
    // @ts-expect-error error from external library
    RdsEndDateDirective,
    RdsDatepickerToggleComponent,
    RdsFormFieldModule,
    RdsDateRangeInputComponent,
    RdsDateRangePickerComponent,
    RdsTimepickerComponent,
    RdsTimepickerInputDirective,
    RdsTimepickerClearComponent,
    RdsButtonModule,
    RdsSwitchModule,
    RdsIconComponent,
    RdsTooltipModule,
    NgOptimizedImage,
  ],
  templateUrl: './room-booking-form.component.html',
  styleUrl: './room-booking-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RoomBookingFormService],
})
export class RoomBookingFormComponent implements OnInit {
  @Input() public set roomDetails(value: RoomDetailsModel | null) {
    if (value) {
      this.#roomBookingFormService.setRoomDataValidators(value);
      this.maxRoomCapacity = value.capacity;
      this.minRoomCapacity = value.minCapacity;
      this.#roomDetails = value;
    }
  }

  @Input() public set roomLayouts(value: RoomLayoutsModel[] | null) {
    if (value && value.length > 0 && this.#roomDetails) {
      this.layouts.set(value);
      this.#roomBookingFormService.setRoomLayoutsValidators(
        value,
        this.#roomDetails.manageSpaceConfiguration?.defaultLayoutId
      );
    }
  }

  @Input() public set bookingRequest(
    booking: BookingRequestDetailsModel | null
  ) {
    if (booking) {
      this.#roomBookingFormService.setFormValues(booking);
    }
  }

  @Output() public bookingRequestFormData =
    new EventEmitter<BookingRequestBaseModel>();
  @Output() public cancelHandler = new EventEmitter<void>();

  readonly #roomBookingFormService = inject(RoomBookingFormService);

  protected readonly bookingDetailsForm =
    this.#roomBookingFormService.roomBookingForm;
  protected readonly minDate: Date = new Date();
  protected readonly layouts = signal<RoomLayoutsModel[]>([]);
  protected maxRoomCapacity: number = 0;
  protected minRoomCapacity: number = 0;
  readonly #destroyRef = inject(DestroyRef);
  #roomDetails: RoomDetailsModel | null = null;

  public ngOnInit() {
    this.#roomBookingFormService.layoutsControlValueChange
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe();
  }

  confirm() {
    this.bookingDetailsForm.markAllAsTouched();
    this.bookingDetailsForm.updateValueAndValidity();
    if (this.bookingDetailsForm.valid) {
      const eventData =
        this.#roomBookingFormService.createRoomBookingRequestBody();
      this.bookingRequestFormData.emit(eventData);
    }
  }

  cancel() {
    this.cancelHandler.emit();
  }
}
