import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgOptimizedImage } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import {
  RdsBadgeComponent,
  RdsButtonModule,
  RdsEmptyStateComponent,
  RdsEmptyStateImageComponent,
  RdsEmptyStateTitleDirective,
  RdsIconComponent,
} from '@rds/angular-components';

import { BookingRequestDetailsModel, EventDateTimePipe } from '@bookly/shared';

@Component({
  selector: 'bus-booking-list',
  standalone: true,
  imports: [
    ScrollingModule,
    NgOptimizedImage,
    RdsButtonModule,
    RdsIconComponent,
    RouterLink,
    RdsBadgeComponent,
    EventDateTimePipe,
    RdsEmptyStateComponent,
    RdsEmptyStateImageComponent,
    RdsEmptyStateTitleDirective,
  ],
  templateUrl: './booking-list.component.html',
  styleUrl: './booking-list.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookingListComponent {
  @Input() public bookings!: BookingRequestDetailsModel[];
}
