import { Injectable, inject, signal } from '@angular/core';
import { EMPTY, finalize, forkJoin, tap } from 'rxjs';

import {
  RoomDetailsModel,
  RoomFiltersModel,
  RoomLayoutsModel,
  RoomQueryParams,
  RoomsApiService,
  RoomsListModel,
} from '@bookly/shared';

@Injectable({
  providedIn: 'root',
})
export class RoomsService {
  readonly #roomsApiService = inject(RoomsApiService);
  readonly #isRoomListLoading = signal(false);
  readonly #isRoomFiltersLoading = signal(false);
  readonly #isRoomDetailsLoading = signal(false);
  readonly #roomsList = signal<RoomsListModel[] | null>(null);
  readonly #roomDetails = signal<RoomDetailsModel | null>(null);
  readonly #roomLayouts = signal<RoomLayoutsModel[] | null>(null);
  readonly #roomFiltersData = signal<RoomFiltersModel | null>(null);

  get roomsList() {
    return this.#roomsList.asReadonly();
  }

  get roomDetails() {
    return this.#roomDetails.asReadonly();
  }

  get roomLayouts() {
    return this.#roomLayouts.asReadonly();
  }

  get isRoomListLoading() {
    return this.#isRoomListLoading.asReadonly();
  }

  get isRoomDetailsLoading() {
    return this.#isRoomDetailsLoading.asReadonly();
  }

  get roomFiltersData() {
    return this.#roomFiltersData.asReadonly();
  }

  public getRooms(filters?: RoomQueryParams) {
    this.#isRoomListLoading.set(true);
    return this.#roomsApiService.getRooms(filters).pipe(
      tap(rooms => this.#roomsList.set(rooms)),
      finalize(() => this.#isRoomListLoading.set(false))
    );
  }

  public getRoomFilters() {
    if (this.#roomFiltersData()) {
      return EMPTY;
    }
    this.#isRoomFiltersLoading.set(true);
    return this.#roomsApiService.getRoomFilters().pipe(
      tap(filters => {
        this.#roomFiltersData.set(filters);
      }),
      finalize(() => this.#isRoomFiltersLoading.set(false))
    );
  }

  public getRoomData(roomId: string) {
    if (this.#roomDetails()?.roomDexId === roomId) {
      return EMPTY;
    }
    this.#roomDetails.set(null);
    this.#roomLayouts.set(null);
    this.#isRoomDetailsLoading.set(true);
    return forkJoin([
      this.#roomsApiService.getRoomDetails(roomId),
      this.#roomsApiService.getLayouts(roomId),
    ]).pipe(
      tap(([roomDetails, roomLayouts]) => {
        this.#roomDetails.set(roomDetails);
        this.#roomLayouts.set(roomLayouts);
      }),
      finalize(() => this.#isRoomDetailsLoading.set(false))
    );
  }
}
