import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  computed,
  inject,
} from '@angular/core';
import {
  RdsButtonModule,
  RdsHeaderModule,
  RdsProgressSpinnerModule,
} from '@rds/angular-components';

import { BookingListComponent } from '../../components/booking-list/booking-list.component';
import { BookingService } from '../../services/booking.service';

export enum BookingTabs {
  UPCOMING = 'upcoming',
  PAST = 'past',
}
@Component({
  selector: 'bus-my-bookings',
  standalone: true,
  imports: [
    BookingListComponent,
    RdsProgressSpinnerModule,
    RdsButtonModule,
    RdsHeaderModule,
  ],
  templateUrl: './my-bookings.component.html',
  styleUrl: './my-bookings.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MyBookingsComponent implements OnInit {
  readonly #bookingService = inject(BookingService);
  protected readonly upcomingBookings = this.#bookingService.upcomingBookings;
  protected readonly pastBookings = this.#bookingService.pastBookings;
  protected readonly isLoading = computed(
    () =>
      this.#bookingService.isPastBookingsLoading() ||
      this.#bookingService.isUpcomingBookingsLoading()
  );
  protected tab: BookingTabs = BookingTabs.UPCOMING;

  ngOnInit() {
    this.#bookingService.getPastBookings().subscribe();
    this.#bookingService.getUpcomingBookings().subscribe();
  }

  protected readonly BookingTabs = BookingTabs;
}
