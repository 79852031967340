import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { isSameDay } from 'date-fns';

@Pipe({
  name: 'eventDateTime',
  standalone: true,
})
export class EventDateTimePipe implements PipeTransform {
  protected readonly datePipe = new DatePipe('en');
  transform(startDate: Date, endDate: Date): string {
    return `${this.datePipe.transform(startDate, 'EEE, d MMM y, HH:mm - ')}${
      isSameDay(startDate, endDate)
        ? this.datePipe.transform(endDate, 'HH:mm')
        : this.datePipe.transform(endDate, 'EEE, d MMM y, HH:mm')
    }`;
  }
}
