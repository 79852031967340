import { Injectable, inject, signal } from '@angular/core';
import { finalize, tap } from 'rxjs';

import { RoomCalendarEventsModel, RoomsApiService } from '@bookly/shared';

@Injectable()
export class RoomBookingCalendarService {
  readonly #roomsApiService = inject(RoomsApiService);
  readonly #roomAvailabilitySignal = signal<RoomCalendarEventsModel[]>([]);
  readonly #isCalendarDataLoadingSignal = signal<boolean>(false);

  get roomAvailability() {
    return this.#roomAvailabilitySignal.asReadonly();
  }

  get isCalendarDataLoading() {
    return this.#isCalendarDataLoadingSignal.asReadonly();
  }

  public getRoomAvailability(
    calendarId: string,
    dateFrom: string,
    dateTo: string
  ) {
    this.#isCalendarDataLoadingSignal.set(true);
    return this.#roomsApiService
      .getRoomAvailability(calendarId, dateFrom, dateTo)
      .pipe(
        tap(availability => {
          this.#roomAvailabilitySignal.set(availability);
        }),
        finalize(() => this.#isCalendarDataLoadingSignal.set(false))
      );
  }
}
