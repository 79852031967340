import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, mergeMap, tap } from 'rxjs';

import { AuthApiService } from '../../auth/services/auth-api.service';
import { AuthService } from '../../auth/services/auth.service';

export const authErrorInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService);
  const authApiService = inject(AuthApiService);
  return next(req).pipe(
    catchError((err: HttpErrorResponse) => {
      const refreshToken = authService.refreshToken;
      if (err.status === 403 && refreshToken) {
        return authApiService.refreshToken(refreshToken).pipe(
          tap(resp => authService.refresh(resp.idtoken)),
          mergeMap(token =>
            next(
              req.clone({
                setHeaders: {
                  Authorization: token.idtoken,
                },
              })
            )
          ),
          catchError(error => {
            void authService.logout();
            throw error;
          })
        );
      }
      throw err;
    })
  );
};
