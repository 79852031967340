@let roomFilters = roomFiltersData();
@let isMobileScreen = isMobileScreen$ | async;

<div class="filters-wrapper">
  <rds-accordion multi [formGroup]="filtersForm">
    <rds-expansion-panel>
      <rds-expansion-panel-header>
        <rds-panel-title> Room name </rds-panel-title>
      </rds-expansion-panel-header>
      <rds-form-field labelPosition="none">
        <input rdsInput placeholder="Room name" formControlName="roomName" />
      </rds-form-field>
    </rds-expansion-panel>
    @if (roomFilters && roomFilters.locations) {
      <rds-expansion-panel>
        <rds-expansion-panel-header>
          <rds-panel-title> Location </rds-panel-title>
        </rds-expansion-panel-header>
        <div class="select-controls-wrapper">
          <rds-form-field labelPosition="none">
            <rds-select placeholder="City" formControlName="siteId">
              @for (location of roomFilters.locations; track location.id) {
                <rds-select-option [value]="location.id">{{
                  location.name
                }}</rds-select-option>
              }
            </rds-select>
          </rds-form-field>
          <rds-form-field labelPosition="none">
            <rds-select
              [placeholder]="
                this.filtersForm.controls.siteId.value
                  ? 'Building'
                  : 'Building - select city'
              "
              formControlName="buildingId">
              @for (building of buildings(); track building.id) {
                <rds-select-option [value]="building.id">{{
                  building.name
                }}</rds-select-option>
              }
            </rds-select>
          </rds-form-field>
          <rds-form-field labelPosition="none">
            <rds-select
              [placeholder]="
                this.filtersForm.controls.buildingId.value
                  ? 'Floor'
                  : 'Floor - select building'
              "
              formControlName="floorId">
              @for (floor of floors(); track floor.id) {
                <rds-select-option [value]="floor.id">{{
                  floor.name
                }}</rds-select-option>
              }
            </rds-select>
          </rds-form-field>
        </div>
      </rds-expansion-panel>
    }

    <rds-expansion-panel>
      <rds-expansion-panel-header>
        <rds-panel-title> Capacity </rds-panel-title>
      </rds-expansion-panel-header>
      <div class="controls-wrapper">
        <rds-form-field labelPosition="none">
          <input
            rdsInput
            placeholder="Min"
            formControlName="minCapacity"
            type="number" />
        </rds-form-field>
        <p class="ui-label-l-bold">to</p>
        <rds-form-field labelPosition="none">
          <input
            rdsInput
            placeholder="Max"
            formControlName="maxCapacity"
            type="number" />
        </rds-form-field>
      </div>
    </rds-expansion-panel>

    <rds-expansion-panel>
      <rds-expansion-panel-header>
        <rds-panel-title> Availability from </rds-panel-title>
      </rds-expansion-panel-header>
      <div class="dates-wrapper">
        <rds-form-field labelPosition="none">
          <input
            rdsInput
            [rdsDatepicker]="datePickerFrom"
            formControlName="fromDate" />
          <rds-datepicker-clear
            rds-control-suffix
            [forDatepicker]="datePickerFrom" />
          <rds-datepicker-toggle rds-control-suffix />
          <rds-datepicker #datePickerFrom></rds-datepicker>
        </rds-form-field>
        <rds-form-field labelPosition="none">
          <input
            rdsInput
            [rdsTimepicker]="timepickerFrom"
            formControlName="fromTime" />
          <rds-timepicker #timepickerFrom />
          <rds-timepicker-clear
            rds-control-suffix
            [forTimepicker]="timepickerFrom" />
        </rds-form-field>
      </div>
      <p class="ui-label-l-bold">to</p>
      <div class="dates-wrapper">
        <rds-form-field labelPosition="none">
          <input
            rdsInput
            [rdsDatepicker]="datePickerTo"
            formControlName="toDate" />
          <rds-datepicker-clear
            rds-control-suffix
            [forDatepicker]="datePickerTo" />
          <rds-datepicker-toggle rds-control-suffix />
          <rds-datepicker #datePickerTo></rds-datepicker>
        </rds-form-field>
        <rds-form-field labelPosition="none">
          <input
            rdsInput
            [rdsTimepicker]="timepickerTo"
            formControlName="toTime" />
          <rds-timepicker #timepickerTo />
          <rds-timepicker-clear
            rds-control-suffix
            [forTimepicker]="timepickerTo" />
        </rds-form-field>
      </div>
    </rds-expansion-panel>

    @if (roomFilters && roomFilters.roomTypes) {
      <rds-expansion-panel>
        <rds-expansion-panel-header>
          <rds-panel-title> Room Type </rds-panel-title>
        </rds-expansion-panel-header>
        <ng-container [formGroup]="filtersForm.controls.roomTypes">
          @for (roomType of roomFilters.roomTypes; track roomType.name) {
            <rds-checkbox [formControlName]="roomType.name">
              {{ roomType.name }}
            </rds-checkbox>
          }
        </ng-container>
      </rds-expansion-panel>
    }

    @if (roomFilters && roomFilters.roomFeatures) {
      <rds-expansion-panel>
        <rds-expansion-panel-header>
          <rds-panel-title> Room must include </rds-panel-title>
        </rds-expansion-panel-header>
        <ng-container [formGroup]="filtersForm.controls.roomFeatures">
          @for (roomFeature of roomFilters.roomFeatures; track roomFeature.id) {
            <rds-checkbox [formControlName]="'' + roomFeature.id">
              {{ roomFeature.name }}
            </rds-checkbox>
          }
        </ng-container>
      </rds-expansion-panel>
    }
  </rds-accordion>
</div>
@if (isMobileScreen?.matches) {
  <div class="button-section">
    <button rds-secondary-button size="m" (click)="resetForm()">
      Clear All
    </button>
    <button rds-primary-button size="m" (click)="applyFilters()">Apply</button>
  </div>
}
