import { HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';

import { environment } from '../../environments';
import { AuthService } from '../services/auth.service';

export const authInterceptor: HttpInterceptorFn = (request, next) => {
  const authService = inject(AuthService);
  if (authService.token && request.url.includes(environment.shared.apiUrl)) {
    request = request.clone({
      setHeaders: {
        Authorization: authService.token,
      },
    });
  }
  return next(request);
};
